@font-face {
  font-family: 'Roboto Mono';
  src: url('../public/RobotoMono-Regular.ttf') format('truetype'); /* Adjust the path */
  font-weight: normal;
  font-style: normal;
}


.App {
  text-align: center;
  background-color: #1A1A1A;
  height: 100%;
}

.recipe {
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1.3em;
}

.recipe li {
  width: 50vw;
  margin-top: 15px;
  margin-bottom: 15px;
  text-align: left;
}


html {
  background-color: #1A1A1A;

}

.header {
  height: 10%;
}


#email {
  color: white;
  font-family: 'Roboto-Mono', monospace;
  font-size: 2vw;
}

.links {
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center !important;
}

.links a{
  color: white;
  text-decoration: none;
  font-family: 'Roboto-Mono', monospace;
  font-size: 2vw;
}

.header img {
  height: 10vw;
}

.about {
  color: white;
  font-family: 'Roboto-Mono', monospace;
}

.section-header {
  font-size: 3.5vw;
  padding-top: 5vw;
  color: white;
  font-family: 'Roboto-Mono', monospace;
}

.about-body {
  padding: 6vh;
}

.about-text {
  text-align: left;
  font-size: 2vw;
  display: flex;
  align-items: center;
}

.image-carousel {
  display: flex;
  justify-content: center;
}

.image-carousel img {
  height: 100%;
  margin-left: 5vw;
  height: 30vw;
  border-radius: 10%;
  border-collapse: separate;
}

.profiles {
  height: 40vw;
}

.profile-card {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.profile-card img {
  height: 23vw;
  width: 16vw;
  border-radius: 3%;
}

.profile-name {
  text-align: left;
  font-family: 'Roboto-Mono', monospace;
  font-size: 4vh;
  font-weight: bold;
}

.profile-role {
  text-align: left;
  font-family: 'Roboto-Mono', monospace;
  font-size: 1.3vw;
}

/*.merch img {
  width: 100%;
  height: 100%;
  padding-top: 10vh;
}*/

.merch-items-mobile {
  display: none;
}

.merch img {
  margin-top: 10vh;
  border-radius: 10%;
}

.merch-items {
  display: flex;
  justify-content: space-evenly;
  padding: 0;
}

.merch-card {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.merch-name {
  color: white;
  font-family: 'Roboto-Mono', monospace;
  margin-top: 4.8vh;
  margin-left: 3vw;
  margin-right: 3vw;
}

.hover-image {
  /*display: inline-block;*/
  transition: transform 0.3s ease; /* Add transition effect for smooth scaling */
}

.hover-image img {
  width: 200px; /* Set the initial width of the image */
}

.hover-image:hover img {
  transform: scale(1.3); /* Scale the image slightly on hover */
}

.games {
  height: 100%;
}

.game {
  padding-top: 5vh;
  display: flex;
}

.game-image img {
  height: 100%;
  width: 95%;
  object-fit: contain;
}

.game-text {
  color: white;
  font-family: 'Roboto-Mono', monospace;
  text-align: left;
}

.game-title {
  font-size: 3.8vh;
  font-weight: bold;
}

.game-subtitle {
  font-size: 2.2vh;
  padding-bottom: 2vh;
}


.connect {
  height: 40vw;
}

.the-form {
  margin-top: 16vh !important;
  border: none !important;
}

.x {
  background-color: white;
  padding: 10%;
  border-radius: 15%;
}



.social-links {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}

.social-link {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

#demo-link {
    text-decoration: none;
    color: white;
  }

.social-link a {
  color: white;
  text-decoration: none;
  font-family: 'Roboto-Mono', monospace;
  font-size: 2.5vw;
  text-align: left;

}

.social-link-inner {
  display: flex;
  text-align: left;
  align-items: center;
  padding-bottom: 5vh;
}

#mobile-games {
  display: none;
}

.social-link img{
  width: 70px;
  margin-right: 20px;
}

@media screen and (min-width: 1921px) {
  .profile-card img {
    height: 42vh;
    width: 30vh;
  }
}

@media (max-width: 1014px) {
  #desktop-games {
    display: none;
  }
  #mobile-games {
    display: inherit;
  }
  .merch img {
    width: 14vw;
  }

  .profile-name {
    font-size: 3.5vw;
  }
  
}

@media (max-width: 995px) {
  .game {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .game-text {
    width: 70vw !important;
    text-align: center;
  }
}

@media (max-width: 768px) {

  #desktop-games {
    display: none;
  }
  #mobile-games {
    display: inherit;
  }

  .header img {
    height: 20vw;
  }

  .header {
    border-bottom: 1px solid white;
    padding-bottom: 2vw;
    padding-top: 3vw;
  }

  .section-header {
    font-size: 8vw;
  }

  .image-carousel img {
    margin-left: 0;
    margin-top: 5vw;
    height: 60vw;
    margin-bottom: 10vh;
    width: auto;
  }

  .links a {
    padding-top: 2vw;
    font-size: 4vw;
  }

  .about-body {
    padding: 3vw;
  }

  .about-text {
    font-size: 3.8vw;
    text-align: center;
  }

  .profiles {
    height: 270vw;
  }

  .profile-card {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .profile-card img {
    height: 55vw;
    width: 40vw;
  }

  .profile-name {
    text-align: center;
    font-size: 5vw;
    margin-top: 1vw;
  }

  .profile-role {
    text-align: center;
    font-size: 3.5vw;
    margin-bottom: 5vw;
  }

  #merch {
    margin-top: 30vw;
  }

  .merch img {
    width: 100%;
  }

  .hover-image {
    width: 19vw;
    height: 100%;
    width: 40vw;
  }

  .merch-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
  }

  .hover-image:hover img {
    transform: none;
  }

  .merch-items {
    display: none;
  }

  .merch-name {
    font-size: 2.5vw;
    margin-top: 10px;
  }


  .merch-items-mobile {
    display: flex;
    overflow: scroll;
    justify-content: space-between;
    margin-top: -50px;
  }

  .mobile-help {
    color: white;
    font-family: 'Roboto-Mono', monospace;
    margin-top: 10px;
  }

  .game-title {
    text-align: center;
  }

   .game-subtitle {
    text-align: center;
  }

   .game-body {
    text-align: center;
  }

  #demo-link {
    text-decoration: none;
  }

  .games {
    height: 120%;
    margin-top: 40px;
  }
  
  .social-links {
    margin-top: 50px;
  }

  .social-link img{
    width: 40px;
    margin-right: 20px;
    object-fit: contain;
}

  .social-link a {
    font-size: 5vw;
    text-align: center;
  }

  
  #email {
    font-size: 3vw;
  }

}